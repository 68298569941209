.bgfundo{
    background: url(bg.png) !important; 
  background-size: cover !important;
}
.bgfundos{
    background: url(bg.png) !important; 
  background-size: cover !important;
  height: 100vh;
}

.margins{
    margin-top: 50px;
}
.total {
    color: white !important;
}

@media (min-width: 900px) {
    .bgbt { background: url(bgbt.png) !important; height: 41px !important; }
    .textsx.x.okx {
        font-size: 27px;
    }
.oksx {
    padding: 50px 193px 10px 135px !important;
} }

@media (max-width: 900px) {
    .margins {
        margin-top: 85px;
        display: flex;
        left: -18px !important;
        padding: 0 !important;
        position: relative;
        justify-content: center;
        flex-wrap: wrap;
    }

.total {
    width: 258px;
    color: white !important;
}
}