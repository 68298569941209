.chakra-modal__footer label.chakra-button, .chakra-modal__footer button.chakra-button { background: #76bc7e; color: white; border-radius: 25px;  /* margin-left: 3px; */ }
.botaounico{width: 45px;}
.noatuar{border-radius: 2px;
  width: inherit !important;}
  .noatuar {
    border-radius: 3px !important;
}
@media (min-width: 1000px) {
.tabelabox {
  padding: 10px 200px 10px 200px;
}}

section#chakra-modal-\:r5j\: {
  background: white;
}

header#chakra-modal--header-\:r5h\: {
  background: white;
}

footer.chakra-modal__footer.css-k0waxj {
  background: #fcffff;
}

textarea#field-\:r5p\: {
  border: solid 1px black;
}

/* EditIntegration.css */
.modal-body {
    background-color: #e5ddd5; /* Cor de fundo do WhatsApp */
  }
  .geral{
    background: url(bg.png) !important; 
  background-size: cover !important;
  }
  
  .input-container {
    background-color: white;
    padding: 10px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
  
  .input-container input {
    border: none;
    outline: none;
    flex: 1;
    margin-left: 10px;
  }
  
  .message-box {
    background-color: #dcf8c6; /* Cor das mensagens enviadas no WhatsApp */
    padding: 10px;
    border-radius: 7.5px;
    margin-bottom: 10px;
    max-width: 75%;
  }
  
  .message-box p {
    margin: 0;
    font-size: 14px;
  }
  
  .messages-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow-y: auto;
    max-height: 300px;
    padding: 10px;
  }
  .chakra-modal__body > input, textarea {
    border: solid 2px #184cb180 !important;
}

  .corpodomodal {
    background: url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png) !important;
    padding: 56px;
    min-height: 358px;
    overflow: scroll;
}

section header { background: white; } .chakra-modal__body { background: white; }
div#chakra-modal--body-\:r17\: {
  min-height: 358px;
}
  