/* Ajustes gerais para o conteúdo da página Home */
.main-content {
  margin-left: 250px; /* Deve ser igual à largura da navbar */
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 100vh;
}
.noatuars{border-radius: 1px !important; width: 99px !important;}


.copiarwebhook {
  margin-left: 37px;
}

.boxgeral {
  padding: 15px 21px 10px 27px !important;
  width: 91% !important;
  height: 170px !important;
  margin-bottom: 25px;
  border-radius: 20px !important;
} 
.rounderd {
  border-radius: 26px !important;
}
.textsx{
  font-weight: 400 !important;
}

.btos {
  background: transparent !important;
  width: 10px;
}

option, optgroup{background-color: white !important;}

.imgplataform {
  width: 107px !important;
}

.boxdefault {
  padding: 15px 20px !important;
}
.boxdefault {
  background: url(bg.png) !important; 
  background-size: cover !important;
}

.topmd {
  margin-top: 77px;
}

.imgbox {
  width: 92px !important;
  height: 100% !important;
}

body {
  background: url(bg.png) !important; 
  background-size: cover !important;
  height: 100vh !important;
}

@media (min-width: 900px) {

  .novoapxp { padding: 41px 136px 10px 115px !important; } 
  

.rounderdx.css-3y4sb {
  margin-bottom: 80px;
}
}

/* Ajustes para dispositivos móveis */
@media (max-width: 768px) {
  .rounderd {
    border-radius: 26px !important;
    width: 100vw;
}

.classn {
  display: none !important;
}

  img.iconeimg { width: 24px; } img.imglogosx { width: 84px; } .montserrat.fonts { font-size: 8px !important; }
  .novoapxp { padding: 81px !important; margin-left: -87px !important; }
  
  .main-content {
      margin-left: 0;
      padding-top: 60px; /* Altura da navbar no modo mobile */
  }

  .boxgeral {
      flex-direction: column !important;
      height: auto !important;
      text-align: center;
  }

  .imgbox {
      width: 60px !important;
      height: 60px !important;
      margin-top: 10px;
  }

  .boxdefault {
      padding: 10px !important;
  }

  .topmd {
      margin-top: 30px;
  }

  .integration-table {
      display: block;
      overflow-x: auto;
  }

  .integration-table table {
      width: 100%;
      border-collapse: collapse;
  }

  .integration-table th, .integration-table td {
      text-align: left;
      padding: 8px;
      border-bottom: 1px solid #ddd;
  }
}

/* Max */
.botaon {
  padding: 25px 21px 25px 21px !important;
  border-radius: 26px !important;
  font-weight: 400 !important;
  font-size: 20px !important;
}
/* Min max*/

@media (min-width: 1200px) and (max-width: 1700px) {
}
 
@media (max-width: 900px) {
  .botaos {
    display: contents !important;
}
.botaon{margin-top: 20px;}
.boxdefault { margin-top: 63px; } }

/* Ajustes para dispositivos muito pequenos */
@media (max-width: 480px) {
  .boxgeral {
      padding: 10px 15px !important;
  }

  .imgbox {
      width: 50px !important;
      height: 50px !important;
  }

  .boxdefault {
      padding: 5px !important;
  }
}

 
.montserrat.fonts {
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 600 !important;
  letter-spacing: normal;
}


.imglogosx{width: 120px;}

@media (min-width: 1800px) {
  .botaos {
    padding: 10px 200px 10px 200px;
}
  .txt {
    font-size: 20px !important;
} 
.txt2 {
  font-weight: 600;
  margin-top: -8px !important;
  position: relative;
  font-size: 18px !important;
}
.icons {
  margin-top: -9px;
}
.topmd {
  margin-top: 77px;
  padding: 20px 200px 20px 200px !important;
} }