.logomob {
    width: 199px !important;
}
.top{
    margin-top: 21px;
}
.iconsx {
    width: 30px;
    margin-right: 9px;
}
.svgicon {
    margin-top: -1px;
}
.bottom{margin-bottom: -24px;}

.fontOpen Sans{
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 500 !important;
}
.opensanss{
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 400 !important;
}
.left{
    margin-left: 12px;
}
.opensans{
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 500 !important;
}
.dashboard{display: none !important;}

div#menu-button-\:r9\: {
    display: flex !important;
    align-items: flex-end !important;
    height: 37px !important;
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
}

.menuhorizontals {
    position: relative;
}

.iconmenu{margin-left: 33px !important;}

.menudesk p {
    font-weight: 400;
}

.menuclass {
    margin-left: -33px;
    width: 97%;
    border-radius: 50px !important;
}