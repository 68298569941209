.fundonew:before {
    position: absolute;
    background: transparent !important;
    content: '';
    height: 100%;
    width: 100%;
    z-index: -1;
}

.fundonew {
    z-index: 1;
    position: relative;
}
.boxlogin{
    border-radius: 21px !important;
}
.fundonew {
    flex-wrap: nowrap !important;
    flex-direction: column !important;
}
.newsx{display: none !important;}

button#menu-list-\:rp\:-menuitem-\:rr\: {
    --menu-bg: transparent !important;
}

.bgnews {
    --menu-bg: transparent !important;
}

div#menu-list-\:r5\: {
    --menu-bg: transparent;
}

button#menu-list-\:r5\:-menuitem-\:r7\: {
    background: transparent !important;
}

.logologin{
    height: 60px !important;
    width: auto !important;
    margin-top: -10px;
    margin-bottom: 30px;
}
.logintxt {
    color: #000000;
    font-family: 'Open Sans';
    font-weight: 300;
}
.txtLogin {
    position: relative;
    font-size: 24px;
    font-family: sans-serif;
    margin-bottom: 20px;
    margin-top: -20px;
    color: white;
    text-shadow: -1px 1px 10px #000000a3;
}

.label{
    margin-left: 10px;
}

.input {
    border-radius: 50px !important;
}
.inputs {
    border-radius: 15px !important;
    border: solid 2px black !important;
    height: 47px !important;
    display: flex;
    color: black !important;
    padding: 14px 0px 12px 46px !important;
}

input:-webkit-autofill{background-color: #ecf1f6 !important;}

.fundonew .chakra-input__left-element {
    margin-top: 4px;
    margin-left: 6px;
}

button.chakra-button {
    margin-top: 6px;
}

.okss {
    font-family: Montserrat;
    font-weight: 400;
  }


label#email-label {
    width: 63px;
    background: #ecf1f6;
    margin-bottom: -13px;
    margin-left: 47px;
    position: relative;
    font-size: 15px;
    text-align: center;
    z-index: 999;
    font-family: Open sans;
    font-weight: 400;
}

  span.chakra-form__required-indicator.css-1tfjd1n {
    display: none;
}
  
  label#password-label {
    width: 63px;
    background: #ecf1f6;
    margin-bottom: -13px;
    margin-left: 47px;
    position: relative;
    font-size: 15px;
    text-align: center;
    z-index: 999;
    font-family: Open sans;
    font-weight: 400;
  }
.icons {
    margin-right: -1px;
}
.okss{
    height: 42px;
}

.nomen {
    margin-top: -13px;
}

.logonavbar {
    width: 231px !important;
}
.help {
    margin-top: 84px !important;
}