.padding img { width: 85px; margin-top: 6px; }
.noatuars{border-radius: 1px !important; width: 99px !important;}
 
.montserrat {
    font-family: 'Montserrat';
    font-weight: 400 !important;
    color: black !important;
}
.border {
    border: solid 1px #00000038;
}

@media (max-width: 900px) {
    
    .botao svg {
        display: none;
    }
.boxgerals .boxgeralssx {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    position: relative;
    margin-top: 14px;
}}

header#chakra-modal--header-\:rb\: { background: white; }
div#chakra-modal--body-\:rb\: {
    background: white;
}
@media (min-width: 1700px) {
.oks {
    padding: 51px;
}
}
@media (min-width: 1000px) {
    

    .menuhorizontals {
        overflow: hidden;
    }
    .headingdobox {
        margin-bottom: 112px !important;
    }
.boxgerals {
    padding: 0px 42px 9px 123px !important;
}}

.botao {
    height: 33px !important;
    font-family: 'Montserrat' !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    width: 108px !important;
}

.montserrat.color {
    color: #38a169 !important;
}

.montserrat.colors {
    color: white !important;
}

.centerxx.css-0 {
    margin-left: 8px;
    margin-top: 15px;
    font-family: 'Montserrat';
}